<template>
  <div>
    <TopHeader class="bg-color-wrap" />
    <MainNavbar />
    <PageBanner pageTitle="Services Two" />
    <OurServices />
    <OurProcess />
    <TechnologySupport />
    <OurClients />
    <OverView class="pt-100" />
    <MainFooter />
  </div>
</template>

<script>
import TopHeader from "@/components/layout/TopHeader";
import MainNavbar from "@/components/layout/MainNavbar";
import PageBanner from "@/components/common/PageBanner";
import OurServices from "@/components/ServicesTwo/OurServices";
import OurProcess from "@/components/ServicesTwo/OurProcess";
import TechnologySupport from "@/components/ServicesTwo/TechnologySupport";
import OurClients from "@/components/common/OurClients";
import OverView from "@/components/common/Overview.vue";
import MainFooter from "@/components/layout/MainFooter";

export default {
  name: "ServicesPageTwo",
  components: {
    TopHeader,
    MainNavbar,
    PageBanner,
    OurServices,
    OurProcess,
    TechnologySupport,
    OurClients,
    OverView,
    MainFooter,
  },
};
</script>
