<template>
  <footer class="footer-area pt-100">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-3 col-sm-6">
          <div class="single-footer-widget">
            <div class="widget-logo">
              <router-link to="/">
                <img
                    src="../../assets/images/logo_color_nb2.png"
                    class="black-logo"
                    alt="logo"
                    style="max-height: 50px"
                />
              </router-link>
            </div>
            <p>
              Dein Partner für nachhaltige Fairsicherungslösungen!
            </p>

            <ul class="widget-info">
              <li>
                <i class="ri-global-line"></i>
                <a href="mailto:info@meinfairein.de">info@meinfairein.de</a>
              </li>
              <li>
                <i class="ri-map-pin-line"></i>
                Lietzenburgerstraße 54, 10719 Berlin
              </li>
            </ul>
          </div>
        </div>
        <!--
        <div class="col-lg-2 col-sm-6">
          <div class="single-footer-widget">
            <h3>Quick Link</h3>

            <ul class="footer-links-list">
              <li><router-link to="/gallery">Gallery</router-link></li>
              <li><router-link to="/about-one">About Us</router-link></li>
              <li><router-link to="/contact">Contact</router-link></li>
              <li>
                <router-link to="/purchase-guide">Purchase Guide</router-link>
              </li>
              <li>
                <router-link to="/terms-of-service"
                  >Terms of Service</router-link
                >
              </li>
              <li><router-link to="/faq">Faq’s</router-link></li>
              <li>
                <router-link to="/privacy-policy">Privacy Policy</router-link>
              </li>
            </ul>
          </div>
        </div>
        -->
        <div class="col-lg-8 col-sm-12">
          <div class="single-footer-widget">
            <br/>
            <h3>Über uns</h3>

            <ul class="footer-links-list">
              <li>
                <router-link to="/">Home</router-link>
              </li>
              <li>
                <router-link to="/terms-of-service">Impressum</router-link>
              </li>
              <li>
                <router-link to="/privacy-policy">Datenschutzerklärung</router-link>
              </li>
              <li>
                <router-link to="/erstinformation">Erstinformation</router-link>
              </li>
              <li>
                <router-link to="/anmeldung">Anmeldung</router-link>
              </li>
            </ul>
          </div>
        </div>

        <!--
        <div class="col-lg-3 col-sm-12">
          <div class="single-footer-widget">
            <h3>Newsletter</h3>

            <div class="widget-newsletter">
              <div class="newsletter-content">
                <p>
                  Best solution for your it startup business consectetur
                  adipiscing elit.
                </p>
              </div>
                            <form class="newsletter-form">
                              <input
                                type="email"
                                class="input-newsletter"
                                placeholder="Enter email"
                                name="EMAIL"
                              />

                              <button type="submit">
                                <i class="ri-send-plane-2-line"></i>
                              </button>
                            </form>
                          </div>

                          <ul class="widget-social">
                            <li>
                              <a href="https://www.facebook.com/" target="_blank">
                                <i class="ri-facebook-line"></i>
                              </a>
                            </li>
                            <li>
                              <a href="https://www.twitter.com/" target="_blank">
                                <i class="ri-twitter-fill"></i>
                              </a>
                            </li>
                            <li>
                              <a href="https://www.linkedin.com/" target="_blank">
                                <i class="ri-linkedin-fill"></i>
                              </a>
                            </li>
                            <li>
                              <a href="https://www.messenger.com/" target="_blank">
                                <i class="ri-messenger-line"></i>
                              </a>
                            </li>
                          </ul>
          </div>
        </div>
                          -->
      </div>
    </div>

    <div class="copyright-area">
      <div class="container">
        <div class="copyright-area-content">
          <p>
            Copyright ©{{ currentYear }} meinFairein
          </p>
        </div>
      </div>
    </div>

    <div class="footer-shape-1">
      <img src="../../assets/images/footer/shape-1.png" alt="image"/>
    </div>
    <div class="footer-shape-2">
      <img src="../../assets/images/footer/shape-2.png" alt="image"/>
    </div>
    <div class="footer-shape-3">
      <img src="../../assets/images/footer/shape-3.png" alt="image"/>
    </div>
    <div class="footer-shape-4">
      <img src="../../assets/images/footer/shape-4.png" alt="image"/>
    </div>
    <div class="footer-shape-5">
      <img src="../../assets/images/footer/shape-5.png" alt="image"/>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MainFooter",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>
