<template>
  <div>
    <TopHeader class="bg-color-wrap"/>
    <MainNavbar/>
    <PageBanner pageTitle="Über Uns"/>
    <UeberUnsComponent/>
    <MainFooter/>
  </div>
</template>

<script>
import TopHeader from "@/components/layout/TopHeader";
import MainNavbar from "@/components/layout/MainNavbar";
import PageBanner from "@/components/common/PageBanner";
import MainFooter from "@/components/layout/MainFooter";
import UeberUnsComponent from "@/components/ueberuns/UeberUns.vue";

export default {
  name: "UeberUnsPage",
  components: {
    UeberUnsComponent,
    TopHeader,
    MainNavbar,
    PageBanner,
    MainFooter,
  },
};
</script>
