<template>
    <div class="plans-area pt-100 pb-100">
        <div class="container">
            <div class="section-title">
                <h2>Choose Your Best Plans</h2>
                <p>Pay by monthly or yearly, you can cancel it anytime whatever you want</p>
            </div>
            <div class="tabs">
                <ul class="tabs__header">
                    <li 
                        class="tabs__header-item" 
                        v-for="tab in tabs" 
                        v-on:click="selectTab (tab.id)" 
                        v-bind:class="{ 'active': activeTab == tab.id }"
                        :key="tab.id"
                    >
                        {{tab.title}}
                    </li>
                </ul>
            </div>
            <div class="tabs__container">
                <div 
                    class="tabs__list"
                    ref='tabsList'
                >
                    <div 
                        class="tabs__list-tab"
                        v-for="tab in tabs"
                        v-bind:class="{ 'active': activeTab == tab.id }"
                        :key="tab.id"
                    >
                        <div class="row justify-content-center">
                            <div 
                                class="col-lg-4 col-md-6"
                                v-for="pricing in tab.pricingBox"
                                :key="pricing.id"
                            >
                                <div class="single-plans-table">
                                    <div class="plans-header">
                                        <h3>{{pricing.title}}</h3>
                                        <p>{{pricing.desc}}</p>
                                    </div>
                                    <div class="price">
                                        ${{pricing.price}} <span>/{{pricing.period}}</span>
                                    </div>
                                    <div class="plans-btn">
                                        <router-link to="/pricing" class="default-btn">
                                            Purchase Now 
                                            <i class="ri-arrow-right-line"></i>
                                            <span></span>
                                        </router-link>
                                    </div>
                                    <ul class="plans-features-list">
                                        <li
                                            v-for="features in pricing.featuresList"
                                            :key="features.id"
                                        >
                                            <i :class="features.icon"></i> 
                                            {{features.list}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="view-all-plans-btn">
                <router-link to="/pricing" class="plans-btn-one">See All Pricing Plan</router-link>
            </div>
        </div>

        <div class="plans-shape-1">
            <img src="../../assets/images/plans/shape-1.png" alt="image">
        </div>
        <div class="plans-shape-2">
            <img src="../../assets/images/plans/shape-2.png" alt="image">
        </div>
        <div class="plans-shape-3">
            <img src="../../assets/images/plans/shape-3.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'BestPlans',
    data () {
        return {
            activeTab: 1,
            offsetRight: 0,
            tabs: [
                {
                    id: 1,
                    title: 'Bill Monthly',
                    pricingBox: [
                        {
                            id: 1,
                            title: 'Individual',
                            desc: 'Powerful & awesome elements',
                            price: '49',
                            period: 'Month',
                            featuresList: [
                                {
                                    id: 1,
                                    icon: 'ri-check-line',
                                    list: 'Up to 10 Website',
                                },
                                {
                                    id: 2,
                                    icon: 'ri-check-line',
                                    list: 'Lifetime free Support',
                                },
                                {
                                    id: 3,
                                    icon: 'ri-check-line',
                                    list: '10 GB Dedicated Hosting free',
                                },
                                {
                                    id: 4,
                                    icon: 'ri-check-line',
                                    list: '24/7 Support',
                                },
                                {
                                    id: 5,
                                    icon: 'ri-check-line',
                                    list: 'SEO Optimized',
                                },
                            ],
                        },
                        {
                            id: 2,
                            title: 'Small Team',
                            desc: 'Powerful & awesome elements',
                            price: '69 ',
                            period: 'Month',
                            featuresList: [
                                {
                                    id: 1,
                                    icon: 'ri-check-line',
                                    list: 'Up to 15 Website',
                                },
                                {
                                    id: 2,
                                    icon: 'ri-check-line',
                                    list: 'Lifetime free Support',
                                },
                                {
                                    id: 3,
                                    icon: 'ri-check-line',
                                    list: '15 GB Dedicated Hosting free',
                                },
                                {
                                    id: 4,
                                    icon: 'ri-check-line',
                                    list: '24/7 Support',
                                },
                                {
                                    id: 5,
                                    icon: 'ri-check-line',
                                    list: 'SEO Optimized',
                                },
                            ],
                        },
                        {
                            id: 3,
                            title: 'Business',
                            desc: 'Powerful & awesome elements',
                            price: '79',
                            period: 'Month',
                            featuresList: [
                                {
                                    id: 1,
                                    icon: 'ri-check-line',
                                    list: 'Up to 20 Website',
                                },
                                {
                                    id: 2,
                                    icon: 'ri-check-line',
                                    list: 'Lifetime free Support',
                                },
                                {
                                    id: 3,
                                    icon: 'ri-check-line',
                                    list: '20 GB Dedicated Hosting free',
                                },
                                {
                                    id: 4,
                                    icon: 'ri-check-line',
                                    list: '24/7 Support',
                                },
                                {
                                    id: 5,
                                    icon: 'ri-check-line',
                                    list: 'SEO Optimized',
                                },
                            ],
                        },
                    ],
                },
                {
                    id: 2,
                    title: 'Bill Yearly',
                    pricingBox: [
                        {
                            id: 1,
                            title: 'Individual',
                            desc: 'Powerful & awesome elements',
                            price: '249',
                            period: 'Yearly',
                            featuresList: [
                                {
                                    id: 1,
                                    icon: 'ri-check-line',
                                    list: 'Up to 10 Website',
                                },
                                {
                                    id: 2,
                                    icon: 'ri-check-line',
                                    list: 'Lifetime free Support',
                                },
                                {
                                    id: 3,
                                    icon: 'ri-check-line',
                                    list: '10 GB Dedicated Hosting free',
                                },
                                {
                                    id: 4,
                                    icon: 'ri-check-line',
                                    list: '24/7 Support',
                                },
                                {
                                    id: 5,
                                    icon: 'ri-check-line',
                                    list: 'SEO Optimized',
                                },
                            ],
                        },
                        {
                            id: 2,
                            title: 'Small Team',
                            desc: 'Powerful & awesome elements',
                            price: '269',
                            period: 'Yearly',
                            featuresList: [
                                {
                                    id: 1,
                                    icon: 'ri-check-line',
                                    list: 'Up to 15 Website',
                                },
                                {
                                    id: 2,
                                    icon: 'ri-check-line',
                                    list: 'Lifetime free Support',
                                },
                                {
                                    id: 3,
                                    icon: 'ri-check-line',
                                    list: '15 GB Dedicated Hosting free',
                                },
                                {
                                    id: 4,
                                    icon: 'ri-check-line',
                                    list: '24/7 Support',
                                },
                                {
                                    id: 5,
                                    icon: 'ri-check-line',
                                    list: 'SEO Optimized',
                                },
                            ],
                        },
                        {
                            id: 3,
                            title: 'Business',
                            desc: 'Powerful & awesome elements',
                            price: '279',
                            period: 'Yearly',
                            featuresList: [
                                {
                                    id: 1,
                                    icon: 'ri-check-line',
                                    list: 'Up to 20 Website',
                                },
                                {
                                    id: 2,
                                    icon: 'ri-check-line',
                                    list: 'Lifetime free Support',
                                },
                                {
                                    id: 3,
                                    icon: 'ri-check-line',
                                    list: '20 GB Dedicated Hosting free',
                                },
                                {
                                    id: 4,
                                    icon: 'ri-check-line',
                                    list: '24/7 Support',
                                },
                                {
                                    id: 5,
                                    icon: 'ri-check-line',
                                    list: 'SEO Optimized',
                                },
                            ],
                        },
                    ],
                },
            ]
        }
    },
    methods: {
        selectTab (id) {
            let tabsList = this.$refs.tabsList
            this.activeTab = id
            this.offsetRight = tabsList.clientWidth * (id - 1)
            tabsList.style.right = this.offsetRight + 'px'
        }
    },
    mounted () {
        let tabsList = this.$refs.tabsList
        tabsList.style.right = this.offsetRight + 'px'
    }
}
</script>