<template>
  <div class="main-banner-area" style="background: #011868">
    <div class="container">
      <div class="row">
        <div class="col-lg-7 col-md-12">
          <div class="main-banner-content">
            <!-- 01.05.24
            <div data-aos="fade-left" data-aos-duration="1000" class="tag" style="background: white">
              <img src="../../assets/images/heart_color_nb.svg" alt="image" style="max-height: 22px; max-width: 22px">
              meinFairein <br/>Dein Partner für erfolgreiches Engagement im Ehrenamt.
            </div>

            -->
            <h3 style="color: white; margin-top: 16px;" data-aos="fade-left" data-aos-duration="1000">Willkommen bei meinFairein -<br/>Dein Partner für erfolgreiches Engagement im Ehrenamt.</h3>
            <!-- 01.05.24
            <p data-aos="fade-left" style="color: white" data-aos-duration="1000">Wir freuen uns, dir unseren einzigartigen Dienst vorstellen zu dürfen. Mit meinFairein als Ansprechpartner trägst du mit deinen bestehenden Versicherungen aktiv dazu bei,deinen Lieblingsfairein finanziell zu unterstützen. Dabei bleiben alle Verträge erhalten.</p>
            -->
            <!--
              <div class="banner-btn">
                  <router-link to="/about-one" class="default-btn">
                      Read More
                      <i class="ri-arrow-right-line"></i>
                      <span></span>
                  </router-link>
              </div> -->
          </div>
        </div>

        <div class="col-lg-5 col-md-12">
          <div class="main-banner-animation-image">
            <img data-aos="fade-down" data-aos-duration="3000" src="../../assets/images/main-banner/banner-one/img1.png"
                 alt="image">
            <img data-aos="fade-down" data-aos-duration="2000" src="../../assets/images/main-banner/banner-one/img2.png"
                 alt="image">
            <img data-aos="fade-down" data-aos-duration="1000" src="../../assets/images/main-banner/banner-one/img3.png"
                 alt="image">
            <img data-aos="fade-down" data-aos-duration="3000" src="../../assets/images/main-banner/banner-one/img4.png"
                 alt="image">
            <img data-aos="fade-up" data-aos-duration="2000" src="../../assets/images/main-banner/banner-one/img5.png"
                 alt="image">
            <img data-aos="fade-down" data-aos-duration="1000" src="../../assets/images/main-banner/banner-one/img6.png"
                 alt="image">
            <img data-aos="fade-down" data-aos-duration="3000" src="../../assets/images/main-banner/banner-one/img7.png"
                 alt="image">
            <img data-aos="fade-down" data-aos-duration="2000" src="../../assets/images/main-banner/banner-one/img8.png"
                 alt="image">
            <img data-aos="fade-down" data-aos-duration="1000" src="../../assets/images/main-banner/banner-one/img9.png"
                 alt="image">
            <img data-aos="fade-down" data-aos-duration="3000"
                 src="../../assets/images/main-banner/banner-one/img10.png" alt="image">
            <img data-aos="fade-down" data-aos-duration="2000"
                 src="../../assets/images/main-banner/banner-one/img11.png" alt="image">
            <img data-aos="fade-left" data-aos-duration="1000"
                 src="../../assets/images/main-banner/banner-one/img12.png" alt="image">
            <img data-aos="fade-left" data-aos-duration="3000"
                 src="../../assets/images/main-banner/banner-one/img13.png" alt="image">
            <img data-aos="fade-left" data-aos-duration="2000"
                 src="../../assets/images/main-banner/banner-one/img14.png" alt="image">
            <img data-aos="fade-left" data-aos-duration="1000"
                 src="../../assets/images/main-banner/banner-one/img15.png" alt="image">
            <img src="../../assets/images/main-banner/banner-one/main-pic.png" alt="image">
          </div>
        </div>
      </div>
    </div>

    <div class="main-banner-shape-2">
      <img src="../../assets/images/main-banner/banner-one/shape-2.png" alt="image">
    </div>
    <div class="main-banner-shape-3">
      <img src="../../assets/images/main-banner/banner-one/shape-3.png" alt="image">
    </div>
    <div class="main-banner-shape-4">
      <img src="../../assets/images/main-banner/banner-one/shape-4.png" alt="image">
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainBanner',
}
</script>
