<template>
  <div>
    <TopHeader class="bg-color-wrap" />
    <MainNavbar />
    <PageBanner pageTitle="Impressum" />
    <TermsOfService />
    <MainFooter />
  </div>
</template>

<script>
import TopHeader from "@/components/layout/TopHeader";
import MainNavbar from "@/components/layout/MainNavbar";
import PageBanner from "@/components/common/PageBanner";
import TermsOfService from "@/components/TermsOfService/TermsOfService";
import MainFooter from "@/components/layout/MainFooter";

export default {
  name: "TermsOfServicePage",
  components: {
    TopHeader,
    MainNavbar,
    PageBanner,
    TermsOfService,
    MainFooter,
  },
};
</script>
