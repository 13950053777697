<template>
  <div>
    <TopHeader class="bg-color-wrap"/>
    <MainNavbar/>
    <PageBanner pageTitle="Unternehmen"/>
    <UnternehmenComponent/>
    <MainFooter/>
  </div>
</template>

<script>
import TopHeader from "@/components/layout/TopHeader";
import MainNavbar from "@/components/layout/MainNavbar";
import PageBanner from "@/components/common/PageBanner";
import MainFooter from "@/components/layout/MainFooter";
import UnternehmenComponent from "@/components/unternehmen/Unternehmen.vue";

export default {
  name: "VereinePage",
  components: {
    UnternehmenComponent,
    TopHeader,
    MainNavbar,
    PageBanner,
    MainFooter,
  },
};
</script>
