<template>
  <div class="experiences-area">
    <div class="container">
      <div class="row align-items-start">
        <div class="col-lg-6">
          <div class="experiences-item">
            <div class="experiences-content">
              <p style="font-weight: bold; text-align: justify">Warum wurde meinFairein gegründet?</p>
              <div class="experiences-inner-content">
                <img src="../../assets/images/heart_color_nb.svg" alt="image" style="max-height: 60px; max-width: 50px">
                <p style="text-align: justify">
                  Wir sind seit vielen Jahren persönlich aktiv in der Vereinsarbeit, in unzähligen Vorständen tätig, und
                  unser Anliegen ist es mit meinFairein eine Möglichkeit zu schaffen, etwas der Gemeinschaft
                  zurückzugeben.
                </p>
              </div>
              <br/>
              <p style="font-weight: bold; text-align: justify">Wir fördern Ehrenamt und treffen damit viel mehr als den
                Zeitgeist:</p>
              <div class="experiences-inner-content">
                <img src="../../assets/images/heart_color_nb.svg" alt="image" style="max-height: 60px; max-width: 50px">
                <p style="text-align: justify">
                  Laut der im Oktober 2022 veröffentlichten Spendenstudie des DZI und des DIW belief sich das jährliche
                  Spendenvolumen im Jahr 2019 auf etwa 12,9 Milliarden Euro, im Vergleich zu rund zehn Milliarden Euro
                  im Jahr 2017.
                </p>
              </div>
              <br/>
              <p style="font-weight: bold; text-align: justify">Win-Win: Dein guter Zweck und unser Geld</p>
              <div class="experiences-inner-content">
                <img src="../../assets/images/heart_color_nb.svg" alt="image" style="max-height: 60px; max-width: 50px">
                <p style="text-align: justify">
                  Über meinFairein kannst du zusätzlich Geld an Vereine, NGOs und gemeinnützige Organisationen geben,
                  <b style="text-underline: black; text-decoration: underline">ohne</b>, dass das Geld aus Deiner eigenen Tasche kommt.
                </p>
              </div>
              <br/>
              <p style="font-weight: bold; text-align: justify">Soziales Engagement steht im Mittelpunkt:</p>
              <div class="experiences-inner-content">
                <img src="../../assets/images/heart_color_nb.svg" alt="image" style="max-height: 60px; max-width: 50px">
                <p style="text-align: justify">
                  Bei meinFairein steht soziales Engagement im Mittelpunkt. Wir geben an die Gesellschaft zurück, das
                  ist uns wichtig.
                </p>
              </div>
              <br/>
              <p style="font-weight: bold; text-align: justify">Einfach und kostenfrei:</p>
              <div class="experiences-inner-content">
                <img src="../../assets/images/heart_color_nb.svg" alt="image" style="max-height: 60px; max-width: 50px">
                <p style="text-align: justify">
                  meinFairein funktioniert über deine bestehende Versicherungspolice.
                  Keine Umwege – keine Mehrkosten - keine Änderung<br/><br/>

                  Mit jeder an meinFairein übertragenen Police oder neu abgeschlossenen Versicherung fließt ein Teil des
                  Beitrags in die Kasse deines Wunschvereins.

                </p>
              </div>
              <br/>
              <p style="font-weight: bold; text-align: justify">Maßgeschneiderte Lösungen für Privat- und
                Geschäftskunden:</p>
              <div class="experiences-inner-content">
                <img src="../../assets/images/heart_color_nb.svg" alt="image" style="max-height: 60px; max-width: 50px">
                <p style="text-align: justify">
                  Für alle, die mehr wollen, finden wir maßgeschneiderte Lösungen für Privat- und Geschäftskunden bei
                  meinFairein. Wir verzichten bewusst auf einen Teil unserer Umsätze, um sicherzustellen, dass unsere
                  Dienstleistung fair für alle ist.
                </p>
              </div>
              <br/>
              <p style="font-weight: bold; text-align: justify">Per App - Simple Handhabung und Alles im Blick:</p>
              <div class="experiences-inner-content">
                <img src="../../assets/images/heart_color_nb.svg" alt="image" style="max-height: 60px; max-width: 50px">
                <p style="text-align: justify">
                  Überlasse uns deine Police zur Betreuung und setze dich gleichzeitig für deine gute Sache ein, die dir
                  am Herzen liegt. Entscheide selbst, welcher Verein oder welche Non-Profit Organisation zukünftig durch
                  uns finanziell unterstützt wird. Der Übertrag deiner bestehenden Policen funktioniert kinderleicht.
                  Mit wenigen Klicks in unserer App ist alles erledigt.
                </p>
              </div>
              <br/>
              <p style="font-weight: bold; text-align: justify">Expertise und Beratung:</p>
              <div class="experiences-inner-content">
                <img src="../../assets/images/heart_color_nb.svg" alt="image" style="max-height: 60px; max-width: 50px">
                <p style="text-align: justify">
                  Bei Optimierungsbedarf stehen dir unsere Finanzexperten zur Seite, um die bestmöglichen Lösungen zu
                  finden, die genau auf deine individuellen Bedürfnisse zugeschnitten sind. Dies gilt sowohl für Privat-
                  als auch für Geschäftskunden.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="experiences-image" style="margin-top: 50px">
            <img
                src="../../assets/images/main-banner/banner-two/main-pic.png"
                alt="image"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="experiences-shape-2">
      <img src="../../assets/images/experiences/shape-2.png" alt="image"/>
    </div>
    <div class="experiences-shape-3">
      <img src="../../assets/images/experiences/shape-3.png" alt="image"/>
    </div>
    <div class="experiences-shape-4">
      <img src="../../assets/images/experiences/shape-2.png" alt="image"/>
    </div>
    <div class="experiences-shape-5">
      <img src="../../assets/images/experiences/shape-3.png" alt="image"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfessionalExperiences",
};
</script>
