<template>
  <div class="privacy-policy-area ptb-100">
    <div class="container">
      <div class="row">
        <div v-if="success">
          <h1>Deine Registrierung war erfolgreich</h1>
          <p>Deine Zugangsdaten hast du soeben per E-Mail erhalten</p>
          <a href="https://login.simplr.de/#/login">ZUM SIMPLR WEB-LOGIN</a>
        </div>
        <div v-else class="row">
          <div class="col-lg-8 col-md-12">
            <h1>Jetzt Zugang anfordern</h1>
            <br/>
            <form>
              <div class="privacy-policy-content">
                <blockquote class="blockquote">
                  <h5>Persönliches</h5>
                </blockquote>
              </div>


              <label for="salutation-select">Anrede</label>

              <select v-model="salutation" name="salutation" id="salutation-select"
                      style="margin-bottom: 8px; margin-left: 16px">
                <option value="1">Herr</option>
                <option value="2">Frau</option>
                <option value="7">Firma</option>
              </select>
              <br/>

              <label v-if='salutation === "7"' for="legal-select">Rechtsform</label>
              <select v-if='salutation === "7"' name="legalForm" id="legal-select" ref="legal"
                      style="margin-bottom: 8px; margin-left: 16px">
                <option value="1">Einzelunternehmer</option>
                <option value="2">GbR</option>
                <option value="3">OHG</option>
                <option value="4">KG</option>
                <option value="5">GmbH</option>
                <option value="6">GmbH & Co KG</option>
                <option value="7">AG</option>
                <option value="8">Limited</option>
                <option value="9">Sonstiges</option>
                <option value="10">UG</option>
                <option value="11">UG & Co KG</option>
                <option value="12">Verein</option>
              </select>

              <div class="form-group" style="margin-bottom: 8px">
                <label>Vorname</label>
                <input type="text" class="form-control" placeholder="Vorname" ref="firstName">
              </div>
              <div class="form-group" style="margin-bottom: 8px">
                <label>Nachname</label>
                <input type="text" class="form-control" placeholder="Nachname" ref="lastName">
              </div>

              <div class="form-group" style="margin-bottom: 8px">
                <label>Nationalität</label>
                <select name="nationalityForm" id="nationality-select" ref="nationality"
                        style="margin-bottom: 8px; margin-left: 16px">
                  <option v-for="nation in nations" :key="nation.id" :value="nation.id">{{ nation.text }}</option>
                </select>
              </div>

              <div class="form-group" style="margin-bottom: 8px">
                <label>Geburtstag</label>
                <input type="date" class="form-control" placeholder="Geburtstag" ref="birthday">
              </div>

              <div class="form-group" style="margin-bottom: 8px">
                <label>Verein mit vollständigem Namen | Abteilung bei Vereinen mit mehreren Angeboten (z.B. Fußball, Cello, etc..) | Ort</label>
                <input type="text" class="form-control" placeholder="Verein" ref="verein">
              </div>

              <br/>
              <div class="privacy-policy-content">
                <blockquote class="blockquote">
                  <h5>Adresse</h5>
                </blockquote>
              </div>

              <div class="form-group" style="margin-bottom: 8px">
                <label>Straße</label>
                <input type="text" class="form-control" placeholder="Straße" ref="street">
              </div>

              <div class="form-group" style="margin-bottom: 8px">
                <label>Postleitzahl</label>
                <input type="text" class="form-control" placeholder="Postleitzahl" ref="zip">
              </div>

              <div class="form-group" style="margin-bottom: 8px">
                <label>Stadt</label>
                <input type="text" class="form-control" placeholder="Stadt" ref="city">
              </div>

              <label for="nation-select">Land</label>
              <select name="nationForm" id="nation-select" ref="nation"
                      style="margin-bottom: 8px; margin-left: 16px">
                <option v-for="nation in nations" :key="nation.id" :value="nation.id">{{ nation.text }}</option>
              </select>
              <br/>
              <br/>
              <div class="privacy-policy-content">
                <blockquote class="blockquote">
                  <h5>Kontakt</h5>
                </blockquote>
              </div>

              <div class="form-group" style="margin-bottom: 8px">
                <label>E-Mail</label>
                <input type="email" class="form-control" placeholder="E-Mail" ref="email">
              </div>
              <div class="form-group" style="margin-bottom: 8px">
                <label>Mobilfunknummer</label>
                <input type="tel" class="form-control" placeholder="Mobilfunknummer" ref="mobile">
              </div>

            </form>
            <br/>
            <button :disabled="loading" @click.stop="register()" type="submit" class="default-btn">Jetzt Registrieren<i
                class="ri-arrow-right-line"></i></button>
            <br/>
            <br/>
            <div style="" v-if="loading">Bitte warten...</div>
            <div style="color: red" v-if="error">Fehler: {{ error }}</div>
          </div>
          <div class="col-lg-4 col-md-12">
            <div class="main-banner-content">
              <div data-aos="fade-left" data-aos-duration="1000" class="tag" style="background: #011868; color: white">
                <img src="../../assets/images/heart_color_nb.svg" alt="image" style="max-height: 22px; max-width: 22px">
                Im Anschluss erhältst du deine Zugangsdaten direkt an die hier hinterlegte E-Mail Adresse!
              </div>
            </div>
            <div class="col-lg-6">
              <br/>
              <br/>
              <img
                  src="../../assets/images/main-banner/banner-two/img3.png"
                  style="text-align: center; display: block; margin-left: auto; margin-right: auto;"
                  alt="image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import axios from "axios";

export default {
  name: 'AnmeldungsSite',
  data() {
    return {
      success: false,
      error: "",
      loading: false,
      salutation: "1",
      nations: [{"id": "de", "text": "Deutschland"}, {"id": "at", "text": "Österreich"}, {
        "id": "ch",
        "text": "Schweiz"
      }, {"id": "gb", "text": "Großbritannien (UK)"}, {"id": "af", "text": "Afghanistan"}, {
        "id": "eg",
        "text": "Ägypten"
      }, {"id": "al", "text": "Albanien"}, {"id": "dz", "text": "Algerien"}, {
        "id": "ad",
        "text": "Andorra"
      }, {"id": "ao", "text": "Angola"}, {"id": "ai", "text": "Anguilla"}, {
        "id": "ag",
        "text": "Antigua und Barbuda"
      }, {"id": "gq", "text": "Äquatorial Guinea"}, {"id": "ar", "text": "Argentinien"}, {
        "id": "am",
        "text": "Armenien"
      }, {"id": "aw", "text": "Aruba"}, {"id": "az", "text": "Aserbaidschan"}, {
        "id": "et",
        "text": "Äthiopien"
      }, {"id": "au", "text": "Australien"}, {"id": "bs", "text": "Bahamas"}, {
        "id": "bh",
        "text": "Bahrain"
      }, {"id": "bd", "text": "Bangladesh"}, {"id": "bb", "text": "Barbados"}, {
        "id": "be",
        "text": "Belgien"
      }, {"id": "bz", "text": "Belize"}, {"id": "bj", "text": "Benin"}, {"id": "bm", "text": "Bermudas"}, {
        "id": "bt",
        "text": "Bhutan"
      }, {"id": "mm", "text": "Birma"}, {"id": "bo", "text": "Bolivien"}, {
        "id": "ba",
        "text": "Bosnien-Herzegowina"
      }, {"id": "bw", "text": "Botswana"}, {"id": "bv", "text": "Bouvet Inseln"}, {
        "id": "br",
        "text": "Brasilien"
      }, {"id": "io", "text": "Britisch-Indischer Ozean"}, {"id": "bn", "text": "Brunei"}, {
        "id": "bg",
        "text": "Bulgarien"
      }, {"id": "bf", "text": "Burkina Faso"}, {"id": "bi", "text": "Burundi"}, {
        "id": "cl",
        "text": "Chile"
      }, {"id": "cn", "text": "China"}, {"id": "cx", "text": "Christmas Island"}, {
        "id": "ck",
        "text": "Cook Inseln"
      }, {"id": "cr", "text": "Costa Rica"}, {"id": "dk", "text": "Dänemark"}, {
        "id": "dj",
        "text": "Djibuti"
      }, {"id": "dm", "text": "Dominika"}, {"id": "do", "text": "Dominikanische Republik"}, {
        "id": "ec",
        "text": "Ecuador"
      }, {"id": "sv", "text": "El Salvador"}, {"id": "ci", "text": "Elfenbeinküste"}, {
        "id": "er",
        "text": "Eritrea"
      }, {"id": "ee", "text": "Estland"}, {"id": "fk", "text": "Falkland Inseln"}, {
        "id": "fo",
        "text": "Färöer Inseln"
      }, {"id": "fj", "text": "Fidschi"}, {"id": "fi", "text": "Finnland"}, {
        "id": "fr",
        "text": "Frankreich"
      }, {"id": "gf", "text": "französisch Guyana"}, {"id": "pf", "text": "Französisch Polynesien"}, {
        "id": "tf",
        "text": "Französisches Süd-Territorium"
      }, {"id": "ga", "text": "Gabun"}, {"id": "gm", "text": "Gambia"}, {"id": "ge", "text": "Georgien"}, {
        "id": "gh",
        "text": "Ghana"
      }, {"id": "gi", "text": "Gibraltar"}, {"id": "gd", "text": "Grenada"}, {
        "id": "gr",
        "text": "Griechenland"
      }, {"id": "gl", "text": "Grönland"}, {"id": "gp", "text": "Guadeloupe"}, {
        "id": "gu",
        "text": "Guam"
      }, {"id": "gt", "text": "Guatemala"}, {"id": "gn", "text": "Guinea"}, {
        "id": "gw",
        "text": "Guinea Bissau"
      }, {"id": "gy", "text": "Guyana"}, {"id": "ht", "text": "Haiti"}, {
        "id": "hm",
        "text": "Heard und McDonald Islands"
      }, {"id": "hn", "text": "Honduras"}, {"id": "hk", "text": "Hong Kong"}, {
        "id": "in",
        "text": "Indien"
      }, {"id": "id", "text": "Indonesien"}, {"id": "iq", "text": "Irak"}, {"id": "ir", "text": "Iran"}, {
        "id": "ie",
        "text": "Irland"
      }, {"id": "is", "text": "Island"}, {"id": "il", "text": "Israel"}, {"id": "it", "text": "Italien"}, {
        "id": "jm",
        "text": "Jamaika"
      }, {"id": "jp", "text": "Japan"}, {"id": "ye", "text": "Jemen"}, {"id": "jo", "text": "Jordanien"}, {
        "id": "ky",
        "text": "Kaiman Inseln"
      }, {"id": "kh", "text": "Kambodscha"}, {"id": "cm", "text": "Kamerun"}, {
        "id": "ca",
        "text": "Kanada"
      }, {"id": "cv", "text": "Kap Verde"}, {"id": "kz", "text": "Kasachstan"}, {
        "id": "ke",
        "text": "Kenia"
      }, {"id": "kg", "text": "Kirgisistan"}, {"id": "ki", "text": "Kiribati"}, {
        "id": "cc",
        "text": "Kokosinseln"
      }, {"id": "co", "text": "Kolumbien"}, {"id": "km", "text": "Komoren"}, {"id": "cg", "text": "Kongo"}, {
        "id": "cd",
        "text": "Kongo,Demokratische Republik"
      }, {"id": "xk", "text": "Kosovo"}, {"id": "hr", "text": "Kroatien"}, {"id": "cu", "text": "Kuba"}, {
        "id": "kw",
        "text": "Kuwait"
      }, {"id": "la", "text": "Laos"}, {"id": "ls", "text": "Lesotho"}, {"id": "lv", "text": "Lettland"}, {
        "id": "lb",
        "text": "Libanon"
      }, {"id": "lr", "text": "Liberia"}, {"id": "ly", "text": "Libyen"}, {
        "id": "li",
        "text": "Liechtenstein"
      }, {"id": "lt", "text": "Litauen"}, {"id": "lu", "text": "Luxemburg"}, {"id": "mo", "text": "Macao"}, {
        "id": "mg",
        "text": "Madagaskar"
      }, {"id": "mw", "text": "Malawi"}, {"id": "my", "text": "Malaysia"}, {
        "id": "mv",
        "text": "Malediven"
      }, {"id": "ml", "text": "Mali"}, {"id": "mt", "text": "Malta"}, {"id": "mp", "text": "Marianen"}, {
        "id": "ma",
        "text": "Marokko"
      }, {"id": "mh", "text": "Marshall Inseln"}, {"id": "mq", "text": "Martinique"}, {
        "id": "mr",
        "text": "Mauretanien"
      }, {"id": "mu", "text": "Mauritius"}, {"id": "yt", "text": "Mayotte"}, {
        "id": "mx",
        "text": "Mexiko"
      }, {"id": "fm", "text": "Mikronesien"}, {"id": "mz", "text": "Mocambique"}, {
        "id": "md",
        "text": "Moldavien"
      }, {"id": "mc", "text": "Monaco"}, {"id": "mn", "text": "Mongolei"}, {
        "id": "me",
        "text": "Montenegro"
      }, {"id": "ms", "text": "Montserrat"}, {"id": "na", "text": "Namibia"}, {
        "id": "nr",
        "text": "Nauru"
      }, {"id": "np", "text": "Nepal"}, {"id": "nc", "text": "Neukaledonien"}, {
        "id": "nz",
        "text": "Neuseeland"
      }, {"id": "ni", "text": "Nicaragua"}, {"id": "nl", "text": "Niederlande"}, {
        "id": "an",
        "text": "Niederländische Antillen"
      }, {"id": "ne", "text": "Niger"}, {"id": "ng", "text": "Nigeria"}, {"id": "nu", "text": "Niue"}, {
        "id": "kp",
        "text": "Nord Korea"
      }, {"id": "mk", "text": "Nordmazedonien"}, {"id": "nf", "text": "Norfolk Inseln"}, {
        "id": "no",
        "text": "Norwegen"
      }, {"id": "om", "text": "Oman"}, {"id": "pk", "text": "Pakistan"}, {"id": "ps", "text": "Palästina"}, {
        "id": "pw",
        "text": "Palau"
      }, {"id": "pa", "text": "Panama"}, {"id": "pg", "text": "Papua Neuguinea"}, {
        "id": "py",
        "text": "Paraguay"
      }, {"id": "pe", "text": "Peru"}, {"id": "ph", "text": "Philippinen"}, {
        "id": "pn",
        "text": "Pitcairn"
      }, {"id": "pl", "text": "Polen"}, {"id": "pt", "text": "Portugal"}, {
        "id": "pr",
        "text": "Puerto Rico"
      }, {"id": "qa", "text": "Qatar"}, {"id": "re", "text": "Reunion"}, {"id": "rw", "text": "Ruanda"}, {
        "id": "ro",
        "text": "Rumänien"
      }, {"id": "ru", "text": "Rußland"}, {"id": "lc", "text": "Saint Lucia"}, {
        "id": "zm",
        "text": "Sambia"
      }, {"id": "as", "text": "Samoa"}, {"id": "sm", "text": "San Marino"}, {
        "id": "st",
        "text": "Sao Tome"
      }, {"id": "sa", "text": "Saudi Arabien"}, {"id": "se", "text": "Schweden"}, {
        "id": "su",
        "text": "Schlumpfhausen"
      }, {"id": "sn", "text": "Senegal"}, {"id": "rs", "text": "Serbien"}, {
        "id": "sc",
        "text": "Seychellen"
      }, {"id": "sl", "text": "Sierra Leone"}, {"id": "sg", "text": "Singapur"}, {
        "id": "sk",
        "text": "Slowakei"
      }, {"id": "si", "text": "Slowenien"}, {"id": "sb", "text": "Solomon Inseln"}, {
        "id": "so",
        "text": "Somalia"
      }, {"id": "es", "text": "Spanien"}, {"id": "lk", "text": "Sri Lanka"}, {
        "id": "sh",
        "text": "St. Helena"
      }, {"id": "kr", "text": "Süd Korea"}, {"id": "za", "text": "Südafrika"}, {
        "id": "sd",
        "text": "Sudan"
      }, {"id": "sr", "text": "Surinam"}, {"id": "sz", "text": "Swasiland"}, {
        "id": "sy",
        "text": "Syrien"
      }, {"id": "tj", "text": "Tadschikistan"}, {"id": "tw", "text": "Taiwan"}, {
        "id": "tz",
        "text": "Tansania"
      }, {"id": "th", "text": "Thailand"}, {"id": "tg", "text": "Togo"}, {"id": "tk", "text": "Tokelau"}, {
        "id": "to",
        "text": "Tonga"
      }, {"id": "tt", "text": "Trinidad Tobago"}, {"id": "td", "text": "Tschad"}, {
        "id": "cz",
        "text": "Tschechische Republik"
      }, {"id": "tn", "text": "Tunesien"}, {"id": "tr", "text": "Türkei"}, {
        "id": "tm",
        "text": "Turkmenistan"
      }, {"id": "tv", "text": "Tuvalu"}, {"id": "ug", "text": "Uganda"}, {"id": "ua", "text": "Ukraine"}, {
        "id": "hu",
        "text": "Ungarn"
      }, {"id": "uy", "text": "Uruguay"}, {"id": "uz", "text": "Usbekistan"}, {
        "id": "vu",
        "text": "Vanuatu"
      }, {"id": "va", "text": "Vatikan"}, {"id": "ve", "text": "Venezuela"}, {
        "id": "ae",
        "text": "Vereinigte Arabische Emirate"
      }, {"id": "us", "text": "Vereinigte Staaten von Amerika"}, {"id": "vn", "text": "Vietnam"}, {
        "id": "wf",
        "text": "Wallis et Futuna"
      }, {"id": "by", "text": "Weißrußland"}, {"id": "eh", "text": "Westsahara"}, {
        "id": "cf",
        "text": "Zentralafrikanische Republik"
      }, {"id": "zw", "text": "Zimbabwe"}, {"id": "cy", "text": "Zypern"}, {
        "id": "ss",
        "text": "Serbo Kroatien"
      }],
    }
  },
  methods: {

    register: async function () {
      let firstName = this.$refs.firstName.value
      let lastName = this.$refs.lastName.value
      let email = this.$refs.email.value
      let mobile = this.$refs.mobile.value
      let verein = this.$refs.verein.value
      let birthday = this.$refs.birthday.valueAsDate
      let nationality = this.$refs.nationality.value

      let street = this.$refs.street.value
      let zip = this.$refs.zip.value
      let city = this.$refs.city.value
      let nation = this.$refs.nation.value

      this.loading = true;
      this.error = "";
      if (firstName.length === 0) {
        this.error = "Bitte Vornamen überprüfen";
        this.loading = false;
      } else if (lastName.length === 0) {
        this.error = "Bitte Nachnamen überprüfen";
        this.loading = false;
      } else if (email.length === 0) {
        this.error = "Bitte Email überprüfen";
        this.loading = false;
      } else if (mobile.length === 0) {
        this.error = "Bitte Telefon überprüfen";
        this.loading = false;
      } else if (verein.length === 0) {
        this.error = "Bitte Verein überprüfen";
        this.loading = false;
      } else if (birthday.length === 0) {
        this.error = "Bitte Geburtstag überprüfen";
        this.loading = false;
      } else if (street.length === 0) {
        this.error = "Bitte Straße überprüfen";
        this.loading = false;
      } else if (zip.length === 0) {
        this.error = "Bitte Postleitzahl überprüfen";
        this.loading = false;
      } else if (city.length === 0) {
        this.error = "Bitte Stadt überprüfen";
        this.loading = false;
      } else {
        let date = birthday.getDate() + "." + (birthday.getMonth() + 1) + "." + birthday.getFullYear()
        let customerRequestData = {
          "verein": verein,
          "customer": {
            "salutation": this.salutation,
            "title": email,
            "firstname": firstName,
            "lastname": lastName,
            "birthdate": date,
            "nationality": nationality,
            "mainAddress": {
              "street": street,
              "zip": zip,
              "city": city,
              "nation": nation
            },
            "defaultCommunication": {
              "email": email,
              "phone": mobile,
              "mobile": mobile
            },
            "legalForm": this.salutation === "7" ? this.$refs.legal.value : null
          }
        };
        let result2 = await axios.post('https://europe-west3-meinfairein.cloudfunctions.net/function-1/helloPROD', customerRequestData)


        this.loading = false;
        if (result2.status === 200) {
          this.success = true;
        } else {
          this.error = "Ein Fehler ist aufgetaucht. Bitte sende uns eine E-Mail"
        }
        return result2;
      }
    }
  }
}
</script>
