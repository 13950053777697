<template>
  <div>
    <TopHeader class="bg-color-wrap" />
    <MainNavbar />
    <PageBanner pageTitle="Features One" />
    <OurFeatures />
    <Experiences />
    <OurClients />
    <OverView class="pt-100" />
    <MainFooter />
  </div>
</template>

<script>
import TopHeader from "@/components/layout/TopHeader";
import MainNavbar from "@/components/layout/MainNavbar";
import PageBanner from "@/components/common/PageBanner";
import OurFeatures from "@/components/FeaturesOne/OurFeatures";
import OurClients from "@/components/common/OurClients";
import OverView from "@/components/common/Overview.vue";
import MainFooter from "@/components/layout/MainFooter";

export default {
  name: "FeaturesPageOne",
  components: {
    TopHeader,
    MainNavbar,
    PageBanner,
    OurFeatures,
    OurClients,
    OverView,
    MainFooter,
  },
};
</script>
