<template>
  <div class="overview-area pb-100">
    <div class="container">
      <div class="overview-box">
        <div class="overview-content">
          <h3>Werde Teil der Community für die gute Sache:</h3>

          <div class="overview-btn">
            <div class="overview-btn-one"
            >Nutze die Chance, deine Fairsicherungen zu
              optimieren und gleichzeitig deinen Lieblingsfairein finanziell zu
              unterstützen. Scanne den QR-Code oder klicke den Link an, um gemeinsam eine positive
              Fairänderung zu bewirken.
            </div>
            <br/>
            <div class="overview-btn-one"
            >Hole dir unsere App: Mit unserer App hast du alle deine Verträge bequem in der Hosentasche.
            </div>
            <div style="margin-top: 16px; margin-bottom: 8px;">
              <vue-qrcode style="max-width: 250px" value="https://meinFairein.de/#/anmeldung"></vue-qrcode>
            </div>
            <img src="../../assets/images/overview/bar.png" alt="image"/>
            <br/>
            <router-link to="anmeldung" class="default-btn">
              Jetzt anmelden
              <i class="ri-arrow-right-line"></i>
              <span></span>
            </router-link>
          </div>

          <div v-if="showStories">
            <div class="row">
              <div class="privacy-policy-content">
                <blockquote class="blockquote">
                  <ul>
                    <li>
                      <p>"Wir waren zuerst skeptisch, der Erfolg ließ jedoch nicht lange auf sich warten. Wir
                        bekommen jeden Monat zusätzliche Zahlungen. Danke meinFairein." - <span
                            style="font-style: italic;">Dirk J., Berlin</span></p>
                    </li>
                    <li>
                      <p>"Unsere Mitglieder sind begeistert. Sie nutzen meinFairein rege.
                        Wir haben das Vereinsheim neu gestrichen." - <span style="font-style: italic;">Jasmin F., Dortmund</span>
                      </p>
                    </li>
                    <li>
                      <p>"In unserem kleinen Schulverein kommen einige Euro an, dies aber stets regelmäßig." -
                        <span style="font-style: italic;">Fatima H. Nürnberg</span></p>
                    </li>
                    <li>
                      <p>"Eine warme Mahlzeit für alle in unserer Straße." - <span style="font-style: italic;">Claudia S, Hamm</span>
                      </p>
                    </li>
                  </ul>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="overview-shape-1">
      <img src="../../assets/images/overview/shape-1.png" alt="image"/>
    </div>
    <div class="overview-shape-2">
      <img src="../../assets/images/overview/shape-2.png" alt="image"/>
    </div>

  </div>
</template>

<script>
export default {
  name: "OverView",
  props: {
    showStories: {
      type: Boolean,
      default: false
    }
  }
};
</script>
