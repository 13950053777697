<template>
  <div class="privacy-policy-area ptb-100">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 col-md-12">
          <div class="privacy-policy-content">
            <p>Der unabhängige Finanzmakler MF Finanzdienstleistungen GmbH hier unter der Bezeichnung meinFairein
              unterscheidet sich schon von Gesetzes wegen von einem Versicherungsagenten bzw. einem angestellten
              Versicherungsvertreter. Diese agieren im Auftrag ihres Versicherungsunternehmens und dürfen daher
              ausschließlich die Produkte ihres Unternehmens anbieten. Der unabhängige Makler kann hingegen nach Wunsch
              und Bedürfnis des Kunden aus der Bandbreite des gesamten Marktes wählen. Solltest Du als Kunde lediglich
              an einer Expertise der bestehenden Versicherungsverträge oder Finanzanlagen ohne weitere Betreuung
              interessiert sein, so sind wir gerne bereit, eine Analyse zu erstellen. Unsere Mitarbeiter haben mehr als
              50 Jahre Erfahrung in sämtlichen Bereichen der Finanzberatung.
            </p>
            <blockquote class="blockquote">
              <p>Umfassendes Beratungsangebot für private und gewerbliche Bereiche
              </p>
            </blockquote>

            <div class="row">
              <div class="col-xl-4 col-lg-12">
                <h3><span>Privat</span></h3>
                <ul>
                  <li>Geldanlagen</li>
                  <li>Sparpläne</li>
                  <li>Aktien</li>
                  <li>Renten</li>
                  <li>ETFs</li>
                  <li>Tagesgeld</li>
                  <li>Festgeld</li>
                </ul>

                <ul>
                  <li>Gebäude-/ Eigenheim-/ Hausratversicherung</li>
                  <li>KFZ-Versicherung</li>
                  <li>Rechtsschutzversicherung</li>
                  <li>Privathaftpflichtversicherung</li>
                  <li>Fahrradversicherung</li>
                  <li>Reiseversicherung</li>
                  <li>Elektrogeräteversicherung</li>
                  <li>Tierversicherung</li>
                  <li>Bausparverträge</li>
                  <li>Unfallversicherung</li>
                  <li>Krankenversicherung</li>
                  <li>Krankentagegeld</li>
                  <li>Berufsunfähigkeitsversicherung</li>
                  <li>Sterbeversicherung</li>
                  <li>Pflegeversicherung</li>
                  <li>dread disease Versicherung (schwere Krankheiten)</li>
                  <li>Lebensversicherung</li>
                  <li>Rentenversicherungen (klassisch, fondsgebunden und with-profit)</li>
                  <li>staatlich geförderte Rentenversicherung</li>
                </ul>
              </div>
              <div class="col-xl-4 col-lg-12">
                <h3><span>Gewerbe</span></h3>
                <ul>
                  <li>Betriebsbündelversicherung (All-Risk) inkl. branchenbezogener Spezialdeckungen</li>
                  <li>Immobilienversicherung</li>
                  <li>Rechtsschutzversicherung</li>
                  <li>Betriebsunterbrechungsversicherung</li>
                  <li>Betriebsunterbrechungsversicherung für Freiberufler</li>
                  <li>Maschinenbruchversicherung</li>
                  <li>KFZ-Flottenversicherung</li>
                  <li>Betriebshaftpflichtversicherung</li>
                  <li>landwirtschaftliche Bündelversicherung</li>
                  <li>Transportversicherung</li>
                  <li>Krankenversicherung für Unternehmen</li>
                  <li>kollektive Unfallversicherung</li>
                  <li>Pensionszusagen</li>
                  <li>Betriebliche Altersvorsorge – sämtliche Durchführungswege</li>
                </ul>
              </div>
              <div class="col-xl-4 col-lg-12">
                <h3><span>Spezialrisiken</span></h3>
                <ul style="list-style-type: '🔔 ';">
                  <li>D&O/Managerhaftpflicht-Versicherung für Unternehmen</li>
                  <li>D&O/Managerhaftpflicht-Versicherung für Vereine</li>
                  <li>Managerrechtschutzversicherung</li>
                  <li>Tierseuchenversicherung</li>
                  <li>Cybercrimeversicherung</li>
                  <li>Kreditversicherung</li>
                  <li>Vermögensschadenhaftpflichtversicherung</li>
                  <li>Bauwesenversicherung</li>
                  <li>Maschinenversicherung (fahrbar und stationär)</li>
                  <li>Kreditversicherung</li>
                  <li>CMR-Versicherung</li>
                  <li>Produktrückrufversicherung</li>
                  <li>Vereinshaftpflichtversicherung</li>
                  <li>Veranstaltungshaftpflichtversicherung</li>
                  <li>Veranstaltungsausfallversicherung</li>
                  <li>Oldtimerversicherung (Haftpflicht und Kasko inkl. Standrisiko)</li>
                  <li>Spezialprodukte für Exekutiv- und Bundeswehrangehörige</li>
                  <li>Forderungsausfall- und Garantieversicherung</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UeberUnsComponent',
  components: {}
}
</script>
