<template>
  <div>
    <TopHeader class="bg-color-wrap"/>
    <MainNavbar/>
    <PageBanner pageTitle="Erstinformation"/>
    <ErstInformation/>
    <MainFooter/>
  </div>
</template>

<script>
import TopHeader from "@/components/layout/TopHeader";
import MainNavbar from "@/components/layout/MainNavbar";
import PageBanner from "@/components/common/PageBanner";
import ErstInformation from "@/components/erstInformation/ErstInformation.vue";
import MainFooter from "@/components/layout/MainFooter";

export default {
  name: "ErstInformationPage",
  components: {
    TopHeader,
    MainNavbar,
    PageBanner,
    ErstInformation,
    MainFooter,
  },
};
</script>
