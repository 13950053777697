<template>
    <div class="privacy-policy-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="privacy-policy-content">
                        <blockquote class="blockquote">
                            <p>Erstinformation nach § § 15,16 der Verordnung über die Versicherungsvermittlung und -beratung (VersVermV):<br/>
                            </p>
                        </blockquote>

                        <p>1. MF Finanzdienstleistungen GmbH Geschäftsführer: Robert Erlbaum</p><br/>
                        <p>2. Gemeldet bei der IHK Berlin als Versicherungsmakler mit einer Erlaubnis nach § 34 d Absatz 1 der Gewerbeordnung (GewO).</p><br/>
                        <p>3. Gemeinsame Registerstelle nach § 11 a Absatz 1 GewO und Eintragung im Vermittlerregister Deutscher Industrie- und Handelskammertag (DIHK) e.V. Breite Straße 29 10178 Berlin
                            Telefon: 0180 600 58 50 (Festnetzpreis 0,20€/Anruf; Mobilfunkpreise maximal 0,60 €/Anruf) Die Eintragung im Vermittlerregister (<a href="https://www.vermittlerregister.info">www.vermittlerregister.info</a>) kann unter folgender Registrierungs- Nummer abgerufen werden: <b>IHK D-CKQ0-7L6QU-75</b></p><br/>
                        <p>4. Hinsichtlich der vermittelten Versicherungsprodukte wird eine Beratung angeboten.</p><br/>
                        <p>5.  Vergütung Für die Tätigkeit als Versicherungsvermittler erhält die MF Finanzdienstleistungen GmbH eine Courtage vom jeweiligen Versicherungsunternehmen, die in der Versicherungsprämie enthalten ist. Darüber hinaus erhält die MF Finanzdienstleistungen GmbH keine weiteren Zuwendungen.</p><br/>
                        <p>6. Bei Streitigkeiten zwischen Versicherungsvermittlern und Versicherungsnehmern kann folgende Schlichtungsstelle angerufen werden: <br/>Schlichtungsstelle für gewerbliche Versicherungs-, Anlage- und Kreditvermittlung, Glockengießerwall 2, 20095 Hamburg 5.</p><br/>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ErstInformation'
}
</script>
