<template>
  <div>
    <TopHeader class="bg-color-wrap"/>
    <MainNavbar/>
    <PageBanner pageTitle="Vereine"/>
    <VereineComponent/>
    <MainFooter/>
  </div>
</template>

<script>
import TopHeader from "@/components/layout/TopHeader";
import MainNavbar from "@/components/layout/MainNavbar";
import PageBanner from "@/components/common/PageBanner";
import MainFooter from "@/components/layout/MainFooter";
import VereineComponent from "@/components/vereine/Vereine.vue";

export default {
  name: "VereinePage",
  components: {
    VereineComponent,
    TopHeader,
    MainNavbar,
    PageBanner,
    MainFooter,
  },
};
</script>
