<template>
  <div>
    <TopHeader class="bg-color-wrap" />
    <MainNavbar />
    <PageBanner pageTitle="Services Details" />
    <ServicesDetails />
    <OurProcess />
    <BestPlans />
    <OverView class="pt-100" />
    <MainFooter />
  </div>
</template>

<script>
import TopHeader from "@/components/layout/TopHeader";
import MainNavbar from "@/components/layout/MainNavbar";
import PageBanner from "@/components/common/PageBanner";
import ServicesDetails from "@/components/ServicesDetails/ServicesDetails";
import OurProcess from "@/components/ServicesDetails/OurProcess";
import BestPlans from "@/components/common/BestPlans";
import OverView from "@/components/common/Overview.vue";
import MainFooter from "@/components/layout/MainFooter";

export default {
  name: "ServicesDetailsPage",
  components: {
    TopHeader,
    MainNavbar,
    PageBanner,
    ServicesDetails,
    OurProcess,
    BestPlans,
    OverView,
    MainFooter,
  },
};
</script>
