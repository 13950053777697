<template>
  <div class="privacy-policy-area ptb-100">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-12">
          <div class="privacy-policy-content">
            <blockquote class="blockquote">
              <p>Mehr Geld für deine Organisation, zum Beispiel NGO, Stiftung oder Verein - ohne dass deine Unterstützer
                in die Tasche greifen müssen!
                <br/><br/>
                Entdecke die Möglichkeiten von meinFairein, um zusätzliche finanzielle Unterstützung von Dritten
                (Firmen- oder Privatspender) zu erhalten.
              </p>
            </blockquote>

            <h3><span>Von Firmenspendern:</span></h3>
            <ul>
              <li>
                <p>Das unterstützende Unternehmen steigert sein Engagement im Bereich Corporate Social Responsibility und Corporate Giving.</p>
              </li>
              <li>
                <p>meinFairein überzeugt bei Bewerbergewinnung und Mitarbeitermotivation im HR-Bereich des Unternehmens.</p>
              </li>
              <li>
                <p>Die unterstützende Firma überträgt betriebliche Altersvorsorge und Versicherungen ihrer Mitarbeiter für den guten Zweck.</p>
              </li>
              <li>
                <p>Das Unternehmen verhilft dem guten Zweck seiner bevorzugten Organisation – also dir als NGO, Stiftung oder Verein, indem es eigene Finanzprodukte an meinFairein weitergibt.</p>
              </li>
            </ul>
            <h3><span>Von Privatspendern:</span></h3>
            <ul>
              <li>
                <p>Diese tragen zur Unterstützung deiner Organisation bei, indem sie private Sparverträge und Versicherungen nutzen.</p>
              </li>
            </ul>
            <p>Zusätzliche Dauerspenden ganz einfach erzielen:</p>
            <ul>
              <li>
                <p>Sende Spendern und Mitgliedern eine Einladungsemail mit dem Anmeldelink zu.</p>
              </li>
              <li>
                <p>Teile den Link zum meinFairein Portal.</p>
              </li>
              <li>
                <p>Gib bestehende Finanzprodukte an und erhalte sofort zusätzliches Geld von meinFairein.</p>
              </li>
              <li>
                <p>meinFairein teilt die erhaltene Courtage mit deiner Organisation.</p>
              </li>
            </ul>

            <p>Hole dir unsere App:</p>
            <ul>
              <li>
                <p>Mit unserer App hast du alle deine Verträge bequem in der Hosentasche.</p>
              </li>
            </ul>
            <h3><span>Erfolgsgeschichten:</span></h3>
            <ul>
              <li>
                <p>"Wir waren zuerst skeptisch, der Erfolg ließ jedoch nicht lange auf sich warten. Wir bekommen jeden Monat zusätzliche Zahlungen. Danke meinFairein." - <span style="font-style: italic;">Dirk J., Berlin</span></p>
              </li>
              <li>
                <p>"Unsere Mitglieder sind begeistert. Sie nutzen meinFairein rege.
                  Wir haben das Vereinsheim neu gestrichen."  - <span style="font-style: italic;">Jasmin F., Dortmund</span>
                </p>
              </li>
              <li>
                <p>"In unserem kleinen Schulverein kommen einige Euro an, dies aber stets regelmäßig."  - <span style="font-style: italic;">Fatima H. Nürnberg</span></p>
              </li>
              <li>
                <p>"Eine warme Mahlzeit für alle in unserer Straße."  - <span style="font-style: italic;">Claudia S, Hamm</span></p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VereineComponent'
}
</script>
