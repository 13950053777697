<template>
  <div>
    <TopHeader class="bg-color-wrap" />
    <MainNavbar />
    <PageBanner pageTitle="Contact" />
    <ContactUs />
    <ContactInfo />
    <MainFooter />
  </div>
</template>

<script>
import TopHeader from "@/components/layout/TopHeader";
import MainNavbar from "@/components/layout/MainNavbar";
import PageBanner from "@/components/common/PageBanner";
import ContactUs from "@/components/contact/ContactUs";
import ContactInfo from "@/components/contact/ContactInfo";
import MainFooter from "@/components/layout/MainFooter";

export default {
  name: "ContactPage",
  components: {
    TopHeader,
    MainNavbar,
    PageBanner,
    ContactUs,
    ContactInfo,
    MainFooter,
  },
};
</script>
