<template>
  <div class="privacy-policy-area ptb-100">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-12">
          <div class="privacy-policy-content">
            <blockquote class="blockquote">
              <p>Willkommen bei meinFairein – dein Partner für Corporate Giving und Mitarbeitermotivation!
              </p>
              <br/>
              <p>Warum solltest du mit uns zusammenarbeiten?</p>
            </blockquote>

            <h5><span>Stärke deine Corporate Social Responsibility:</span></h5>
            <ul>
              <li>
                <p>Zeige dein soziales Engagement nach außen.</p>
              </li>
            </ul>

            <h5><span>Bindung und Gewinnung von Mitarbeitern: </span></h5>
            <ul>
              <li>
                <p>Biete attraktive Benefits in einem hart umkämpften Arbeitsmarkt und übertrage die bestehende
                  betriebliche Altersvorsorge und Versicherungen Deiner Mitarbeiter für den guten Zweck.</p>
              </li>
            </ul>

            <h5><span>Mitarbeiterengagement: </span></h5>
            <ul>
              <li>
                <p>Motiviere deine Mitarbeiter, ihre eigenen Versicherungen hochzuladen und lasse sie aktiv am
                  Spendenprozess teilnehmen.</p>
              </li>
            </ul>

            <h5><span>Einfacher Prozess:</span></h5>
            <ul>
              <li>
                <p>Definiere ein Spendenziel und nenne uns Deine Lieblingsorganisation (NGO, Stiftung oder Verein) für
                  den Guten Zweck. Registriere anschliessend bestehende Policen wie Betriebshaftpflicht, Gebäude- und
                  KFZ-Versicherungen sowie die betriebliche Altersvorsorge auf meinFairein.</p>
              </li>
            </ul>

            <h5><span>Kostenfrei:</span></h5>
            <ul>
              <li>
                <p>Spende im Namen deines Unternehmens, ohne zusätzliche Kosten.
                </p>
              </li>
            </ul>

            <h5><b>Mach mit uns einen Unterschied – für dein Unternehmen und die Gemeinschaft und hole dir unsere
              App:</b></h5>
            <ul>
              <li>
                <p>Mit unserer App hast du alle deine Verträge bequem in der Hosentasche.
                </p>
              </li>
            </ul>
            
            <OverView class="pt-100"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OverView from "@/components/common/Overview.vue";

export default {
  name: 'UnternehmenComponent',
  components: {OverView}
}
</script>
