<template>
  <div>
    <PreLoader v-if="isLoading" />
    <router-view />
    <BackToTop />
  </div>
</template>

<script>
import PreLoader from "./components/layout/Preloader.vue";
import BackToTop from "./components/layout/BackToTop.vue";

export default {
  name: "App",
  components: {
    PreLoader,
    BackToTop,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  },
};
</script>
