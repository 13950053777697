<template>
  <div>
    <div :class="['navbar-area', { 'is-sticky': isSticky }]" style="background: white">
      <div class="main-navbar">
        <div class="container">
          <nav class="navbar navbar-expand-xl navbar-light">
            <router-link class="navbar-brand" to="/">
              <img
                  src="../../assets/images/Color_logo_with_background.png"
                  class="black-logo"
                  alt="logo"
                  style="max-height: 60px"
              />
              <!-- <img src="../../assets/images/white-logo.png" class="white-logo" alt="logo"> -->
            </router-link>

            <div
                class="navbar-toggler"
                @click="active = !active"
                :aria-pressed="active ? 'true' : 'false'"
                v-bind:class="{ active: button_active_state }"
                v-on:click="button_active_state = !button_active_state"
            >
              <i class="ri-menu-line"></i>
              <i class="ri-close-line"></i>
            </div>

            <div class="collapse navbar-collapse" :class="{ show: active }">
              <ul class="navbar-nav m-auto">
                <li class="nav-item">
                  <router-link to="/" class="nav-link">
                    Home
                  </router-link>
                </li>

                <li class="nav-item">
                  <router-link to="/vereine" class="nav-link">
                    Vereine
                  </router-link>
                </li>

                <li class="nav-item">
                  <router-link to="/unternehmen" class="nav-link">
                    Unternehmen
                  </router-link>
                </li>

                <li class="nav-item">
                  <a href="#" class="nav-link" >
                    Über uns
                    <i class="ri-add-line"></i>
                  </a>

                  <ul class="dropdown-menu">
                    <li class="nav-item">
                      <router-link to="/about" class="nav-link">
                        Über uns
                      </router-link>
                    </li>

                    <li class="nav-item">
                      <router-link to="/terms-of-service" class="nav-link">
                        Impressum
                      </router-link>
                    </li>

                      <li class="nav-item">
                          <router-link to="/privacy-policy" class="nav-link">
                              Datenschutzerklärung
                          </router-link>
                      </li>

                      <li class="nav-item">
                          <router-link to="/erstinformation" class="nav-link">
                              Erstinformation
                          </router-link>
                      </li>

                      <li class="nav-item">
                          <router-link to="/anmeldung" class="nav-link">
                              Anmeldung
                          </router-link>
                      </li>
                  </ul>
                </li>
              </ul>
            </div>


            <!--  <div class="option-item">
                <router-link to="/contact" class="default-btn">
                  Let’s Talk
                  <i class="ri-message-line"></i>
                  <span></span>
                </router-link>
              </div>
              -->
          </nav>
        </div>
      </div>
    </div>

    <div class="sidebarModal modal right" v-if="isSideMenu">
      <div class="modal-dialog">
        <div class="modal-content">
          <button
              type="button"
              class="close"
              v-on:click="isSideMenuMethod(isSideMenu)"
          >
            <i class="ri-close-line"></i>
          </button>

          <div class="modal-body">
            <div class="title">
              <router-link to="/">
                <img
                    src="../../assets/images/logo.png"
                    class="black-logo"
                    alt="logo"
                />
                <!-- <img src="../../assets/images/white-logo.png" class="white-logo" alt="logo"> -->
              </router-link>
            </div>

            <div class="sidebar-content">
              <h3>About Us</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipiscing elit sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>

              <div class="sidebar-btn">
                <router-link to="/contact" class="default-btn">
                  Let’s Talk
                  <i class="ri-message-line"></i>
                  <span></span>
                </router-link>
              </div>
            </div>

            <div class="sidebar-contact-info">
              <h3>Contact Information</h3>

              <ul class="info-list">
                <li>
                  <i class="ri-phone-fill"></i>
                  <a href="tel:+11234567890">+1 (123) 456 7890</a>
                </li>

                <li>
                  <i class="ri-mail-line"></i>
                  <a href="mailto:info@plob.com">info@plob.com</a>
                </li>

                <li>
                  <i class="ri-map-pin-line"></i> 121 King St, Melbourne VIC
                  <br/>
                  3000, Australia.
                </li>
              </ul>
            </div>

            <ul class="sidebar-social-list">
              <li>
                <a href="https://www.facebook.com/" target="_blank"
                ><i class="ri-facebook-fill"></i
                ></a>
              </li>
              <li>
                <a href="https://www.twitter.com/" target="_blank"
                ><i class="ri-twitter-fill"></i
                ></a>
              </li>
              <li>
                <a href="https://www.linkedin.com/" target="_blank"
                ><i class="ri-linkedin-fill"></i
                ></a>
              </li>
              <li>
                <a href="https://www.instagram.com/" target="_blank"
                ><i class="ri-instagram-fill"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainNavbar",
  data() {
    return {
      isSticky: false,
      active: false,
      button_active_state: false,
      search: false,
      button_search_state: false,
      isSideMenu: false,
    };
  },
  mounted() {
    const that = this;
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      if (scrollPos >= 100) {
        that.isSticky = true;
      } else {
        that.isSticky = false;
      }
    });
  },
  methods: {
    isSideMenuMethod(isSideMenu) {
      return (this.isSideMenu = !isSideMenu);
    },
  },
};
</script>
