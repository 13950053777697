<template>
  <div>
    <TopHeader class="bg-color-wrap"/>
    <MainNavbar/>
    <PageBanner pageTitle="Anmeldung"/>
    <Anmeldung/>
    <MainFooter/>
  </div>
</template>

<script>
import TopHeader from "@/components/layout/TopHeader";
import MainNavbar from "@/components/layout/MainNavbar";
import PageBanner from "@/components/common/PageBanner";
import MainFooter from "@/components/layout/MainFooter";
import Anmeldung from "@/components/anmeldung/Anmeldung.vue";

export default {
  name: "AnmeldungPage",
  components: {
    Anmeldung,
    TopHeader,
    MainNavbar,
    PageBanner,
    MainFooter,
  },
};
</script>
