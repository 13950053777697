<template>
  <div class="contact-area ptb-100">
    <div class="container">
      <div class="contact-form">
        <h3>Get in Touch</h3>

        <form id="contactForm">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <input
                  type="text"
                  name="name"
                  id="name"
                  class="form-control"
                  placeholder="Name*"
                />
              </div>
            </div>

            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <input
                  type="email"
                  name="email"
                  id="email"
                  class="form-control"
                  placeholder="Email*"
                />
              </div>
            </div>

            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <input
                  type="text"
                  name="phone_number"
                  id="phone_number"
                  class="form-control"
                  placeholder="Phone"
                />
              </div>
            </div>

            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <input
                  type="text"
                  name="msg_subject"
                  class="form-control"
                  placeholder="Subject"
                />
              </div>
            </div>

            <div class="col-lg-12 col-md-12">
              <div class="form-group">
                <textarea
                  name="message"
                  class="form-control"
                  id="message"
                  cols="30"
                  rows="5"
                  placeholder="Message"
                ></textarea>
              </div>
            </div>

            <div class="col-lg-12 col-md-12">
              <button type="submit" class="default-btn">
                Send Message <i class="ri-arrow-right-line"></i><span></span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactUs",
};
</script>