import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import AOS from "aos";
import "aos/dist/aos.css";
import VueQrcode from '@chenfengyuan/vue-qrcode';

import "./assets/custom.scss";

const app = createApp(App).use(router);
app.component(VueQrcode.name, VueQrcode);
app.use(AOS.init());
app.mount("#app");
