<template>
  <div class="support-area">
    <div class="container">
      <div class="support-content">
        <div class="tag">
          <img src="../../assets/images/experiences/tag-icon.png" alt="image" />
        </div>

        <h3>Lightning-Fast Tech Support, Guaranteed</h3>
        <p>
          As a midsize software development company, we combine the best of both
          worlds. We have the focus and speed of the small IT outsourcing
          companies along with the scalability and expertise of the big ones.
        </p>
        <span>Regan Rosen, CEO at Flod Agency</span>
      </div>
    </div>

    <div class="support-shape-1">
      <img src="../../assets/images/support/shape-1.png" alt="image" />
    </div>
    <div class="support-shape-2">
      <img src="../../assets/images/support/shape-2.png" alt="image" />
    </div>
    <div class="support-shape-3">
      <img src="../../assets/images/support/shape-3.png" alt="image" />
    </div>
    <div class="support-shape-4">
      <img src="../../assets/images/support/shape-4.png" alt="image" />
    </div>
    <div class="support-shape-5">
      <img src="../../assets/images/support/shape-5.png" alt="image" />
    </div>
    <div class="support-shape-6">
      <img src="../../assets/images/support/shape-6.png" alt="image" />
    </div>
  </div>
</template>

<script>
export default {
  name: "TechSupport",
};
</script>