<template>
  <div class="process-area pb-70">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-sm-6">
          <div class="single-process-box">
            <i class="ri-hard-drive-line"></i>
            <p>Research</p>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="single-process-box bg-36CC72">
            <i class="ri-pie-chart-line"></i>
            <p>Analyze</p>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="single-process-box bg-FF414B">
            <i class="ri-quill-pen-line"></i>
            <p>Design</p>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="single-process-box bg-FF6D3D">
            <i class="ri-focus-line"></i>
            <p>Testing</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurProcess",
};
</script>