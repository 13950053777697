<template>
  <div style="background: #011868">
    <TopHeader class="bg-color-wrap"/>
    <MainNavbar/>
    <MainBanner/>
    <ProfessionalExperiences/>
    <FaqAccordion/>
    <OverView :show-stories="true"/>
    <MainFooter/>
  </div>
</template>

<script>
import MainNavbar from "@/components/layout/MainNavbar";
import MainBanner from "@/components/HomeOne/MainBanner";
import OverView from "@/components/common/Overview.vue";
import MainFooter from "@/components/layout/MainFooter";
import TopHeader from "@/components/layout/TopHeader.vue";
import FaqAccordion from "@/components/aboutOne/FaqAccordion.vue";
import ProfessionalExperiences from "@/components/common/ProfessionalExperiences.vue";

export default {
  name: "HomePageOne",
  components: {
    ProfessionalExperiences,
    FaqAccordion,
    TopHeader,
    MainNavbar,
    MainBanner,
    OverView,
    MainFooter,
  },
};
</script>
