import {createRouter, createWebHashHistory} from "vue-router";

// All Pages
import HomePageOne from "@/components/pages/HomePageOne";
import FeaturesPageOne from "@/components/pages/FeaturesPageOne";
import FeaturesPageTwo from "@/components/pages/FeaturesPageTwo";
import TestimonialsPage from "@/components/pages/TestimonialsPage";
import ServicesPageOne from "@/components/pages/ServicesPageOne";
import ServicesPageTwo from "@/components/pages/ServicesPageTwo";
import ServicesPageThree from "@/components/pages/ServicesPageThree";
import ServicesDetailsPage from "@/components/pages/ServicesDetailsPage";
import TermsOfServicePage from "@/components/pages/TermsOfServicePage";
import PrivacyPolicyPage from "@/components/pages/PrivacyPolicyPage";
import ComingSoonPage from "@/components/pages/ComingSoonPage";
import ErrorPage from "@/components/pages/ErrorPage";
import ErstInformationPage from "@/components/pages/ErstInformationPage.vue";
import ContactPage from "@/components/pages/ContactPage";
import VereinePage from "@/components/pages/VereinePage.vue";
import AnmeldungPage from "@/components/pages/AnmeldungPage.vue";
import UnternehmenPage from "@/components/pages/UnternehmenPage.vue";
import UeberUnsPage from "@/components/pages/UberUnsPage.vue";

const routes = [
    {path: "/", component: HomePageOne},
    {path: "/about", component: UeberUnsPage},
    {path: "/vereine", component: VereinePage},
    {path: "/unternehmen", component: UnternehmenPage},
    {path: "/features-one", component: FeaturesPageOne},
    {path: "/features-two", component: FeaturesPageTwo},
    {path: "/testimonials", component: TestimonialsPage},
    {path: "/services-one", component: ServicesPageOne},
    {path: "/services-two", component: ServicesPageTwo},
    {path: "/services-three", component: ServicesPageThree},
    {path: "/services-details", component: ServicesDetailsPage},
    {path: "/terms-of-service", component: TermsOfServicePage},
    {path: "/privacy-policy", component: PrivacyPolicyPage},
    {path: "/erstInformation", component: ErstInformationPage},
    {path: "/anmeldung", component: AnmeldungPage},
    {path: "/coming-soon", component: ComingSoonPage},
    {path: "/:pathMatch(.*)*", component: ErrorPage},
    {path: "/contact", component: ContactPage},
];

const router = createRouter({
    history: createWebHashHistory(),
    linkExactActiveClass: "active",
    routes,
    scrollBehavior() {
        return {top: 0};
    },
});

export default router;
