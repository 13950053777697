<template>            
    <div class="top-header-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-6">
                    <ul class="top-header-content">
                        <li>
                            <i class="ri-earth-line"></i>
                            <a href="mailto:info@meinfairein.de">info@meinfairein.de</a>
                        </li>
                    </ul>
                </div>
<!--
                <div class="col-lg-5 col-md-6">
                    <ul class="top-header-optional">
                        <li>
                            <a href="https://www.facebook.com/" target="_blank">
                                <i class="ri-facebook-fill"></i>
                            </a>
                            <a href="https://www.twitter.com/" target="_blank">
                                <i class="ri-twitter-fill"></i>
                            </a>
                            <a href="https://www.linkedin.com/" target="_blank">
                                <i class="ri-linkedin-fill"></i>
                            </a>
                            <a href="https://www.messenger.com/" target="_blank">
                                <i class="ri-messenger-fill"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TopHeader'
}
</script>
