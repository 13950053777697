<template>
  <div class="faq-area ptb-100">
    <div class="container">
      <div class="section-title">
        <h2 style="color: white">Häufig gestellte Fragen</h2>
      </div>

      <div class="row align-items-top">
        <div class="col-lg-4 col-md-12">
          <div class="faq-image">
            <img src="../../assets/images/faq/faq-1.png" alt="image" />
          </div>
        </div>

        <div class="col-lg-8 col-md-12">
          <div class="faq-accordion">
            <AccordionSlot>
              <accordion-item>
                <template v-slot:accordion-trigger>
                  <button class="accordion-title" style="color: white">
                    <i class="ri-add-line" style="color: white"></i>
                    An wen wende ich mich im Schadensfall?
                  </button>
                </template>
                <template v-slot:accordion-content>
                  <div class="accordion-body" style="margin-left: 25px;">
                    <p style="color: white">
                      Die Schadenspezialisten von meinFairein stehen dir im Schadenfall zur Verfügung.<br/>
                      Einfach Schaden melden, wir kümmern uns um den Rest.
                    </p>
                  </div>
                </template>
              </accordion-item>
              <accordion-item>
                <template v-slot:accordion-trigger>
                  <button class="accordion-title" style="color: white">
                    <i class="ri-add-line" style="color: white"></i>
                    Ich bin umgezogen, was muss ich beachten?
                  </button>
                </template>
                <template v-slot:accordion-content>
                    <div class="accordion-body" style="margin-left: 25px;">
                    <p style="color: white">
                      Sobald du uns Nachricht gibst, informieren wir sämtliche Vertragspartner für dich automatisch.
                    </p>
                  </div>
                </template>
              </accordion-item>
              <accordion-item>
                <template v-slot:accordion-trigger>
                  <button class="accordion-title" style="color: white">
                    <i class="ri-add-line" style="color: white"></i>
                    Mein Status hat sich durch Heirat/Scheidung etc. verändert. Was muss ich tun?
                  </button>
                </template>
                <template v-slot:accordion-content>
                    <div class="accordion-body" style="margin-left: 25px;">
                    <p style="color: white">
                      Wir unterbreiten dir Umstellungsangebote, die zu deiner neuen Situation passen. Du entscheidest und wir kümmern uns um die Umsetzung.
                    </p>
                  </div>
                </template>
              </accordion-item>
              <accordion-item>
                <template v-slot:accordion-trigger>
                  <button class="accordion-title" style="color: white">
                    <i class="ri-add-line" style="color: white"></i>
                    Kann ich meinFairein weiterempfehlen?
                  </button>
                </template>
                <template v-slot:accordion-content>
                    <div class="accordion-body" style="margin-left: 25px;">
                    <p style="color: white">
                      Selbstverständlich. Deine Empfehlung klickt den Link auf der Homepage und automatisch erhält sein/ihr Wunschverein ebenfalls Geld.
                      <br/>Zusätzlich erhält dein Verein eine Prämie.
                    </p>
                  </div>
                </template>
              </accordion-item>
              <accordion-item>
                <template v-slot:accordion-trigger>
                  <button class="accordion-title" style="color: white">
                    <i class="ri-add-line" style="color: white"></i>
                    Ich habe ein Investmentdepot. Bekommt mein Verein auch hierfür Geld?
                  </button>
                </template>
                <template v-slot:accordion-content>
                    <div class="accordion-body" style="margin-left: 25px;">
                    <p style="color: white">
                      Ja, auch Depots können wir übertragen. Je nach Verwahrstelle gibt es ein Formular.
                    </p>
                  </div>
                </template>
              </accordion-item>
              <accordion-item>
                <template v-slot:accordion-trigger>
                  <button class="accordion-title" style="color: white">
                    <i class="ri-add-line" style="color: white"></i>
                    Kann meinFairein meine bestehenden Versicherungen auf Preis<br/>und/oder Leistung überprüfen?<br/>Was kostet das?
                  </button>
                </template>
                <template v-slot:accordion-content>
                    <div class="accordion-body" style="margin-left: 25px;">
                    <p style="color: white">
                      Selbstverständlich! Der Service ist kostenlos und auch bei einem Wechsel profitiert dein Wunschverein weiterhin.
                    </p>
                  </div>
                </template>
              </accordion-item>
              <accordion-item>
                <template v-slot:accordion-trigger>
                  <button class="accordion-title" style="color: white">
                    <i class="ri-add-line" style="color: white"></i>
                    Muss ich die App benutzen?
                  </button>
                </template>
                <template v-slot:accordion-content>
                    <div class="accordion-body" style="margin-left: 25px;">
                    <p style="color: white">
                      Nein, du hast auch die Möglichkeit alles im geschützten Bereich der Webseite anzusehen.
                    </p>
                  </div>
                </template>
              </accordion-item>
              <accordion-item>
                <template v-slot:accordion-trigger>
                  <button class="accordion-title" style="color: white">
                    <i class="ri-add-line" style="color: white"></i>
                    Wie erhält meinFairein Informationen über meine Versicherungen?
                  </button>
                </template>
                <template v-slot:accordion-content>
                    <div class="accordion-body" style="margin-left: 25px;">
                    <p style="color: white">
                      Einfach mit der App ein Foto eines Briefs oder der Police der Versicherung machen mit Versicherungsnamen und Nummer und es funktioniert automatisch.
                      <br/>Alternativ die Namen und Nummern in App oder Web eintragen.
                    </p>
                  </div>
                </template>
              </accordion-item>
              <accordion-item>
                <template v-slot:accordion-trigger>
                  <button class="accordion-title" style="color: white">
                    <i class="ri-add-line" style="color: white"></i>
                    Erhält mein Wunschverein Informationen über meine Finanzen?
                  </button>
                </template>
                <template v-slot:accordion-content>
                    <div class="accordion-body" style="margin-left: 25px;">
                    <p style="color: white">
                      Nein! Deine Daten sind bei uns sicher. Dein Wunschverein erhält auf Wunsch eine anonymisierte Abrechnung.
                    </p>
                  </div>
                </template>
              </accordion-item>
            </AccordionSlot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccordionSlot from "@/components/common/AccordionSlot";
import AccordionItem from "@/components/common/AccordionItem";

export default {
  name: "FaqAccordion",
  components: {
    AccordionSlot,
    AccordionItem,
  },
};
</script>
